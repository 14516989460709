// extracted by mini-css-extract-plugin
export var projectHeader = "project-module--projectHeader--8e0bd";
export var headerBackground = "project-module--headerBackground--e6150";
export var headerInner = "project-module--headerInner--2ae67";
export var headerPlaceholder = "project-module--headerPlaceholder--d46ca";
export var projectAbout = "project-module--projectAbout--2e199";
export var projectLinks = "project-module--projectLinks--a7272";
export var linkList = "project-module--linkList--28d32";
export var fab = "project-module--fab--59ab5";
export var fas = "project-module--fas--2ee3d";
export var fa = "project-module--fa--b3e36";
export var far = "project-module--far--5c284";
export var fal = "project-module--fal--b06c2";